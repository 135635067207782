import React, { useEffect } from "react";
import styled from "styled-components";
import { LoginButton } from "./LoginButton";
import { Text, Flex, Box } from "rebass/styled-components";
import { Link } from "rebass";
import theme from "../gatsby-plugin-theme-ui";

export const Header = () => {
  return (
    <Box maxWidth={1000} mx={"auto"}>
      <Flex p={24} pb={12} color="black" bg="white" alignItems="center">
        <Link href={"/"} style={{ textDecoration: "none", color: "black" }}>
          <Logo />
        </Link>

        <Box mx="auto" />

        <MenuLink variant="nav" href="/sklepy-konopne-cbd">
          <Text>Sklepy</Text>
        </MenuLink>

        <MenuLink variant="nav" href="#mapa-sklepow-konopnych">
          <Text>Mapa</Text>
        </MenuLink>

        <LoginButton />
      </Flex>
    </Box>
  );
};

const MenuLink = props => (
  <Link
    mr={24}
    sx={{
      textDecoration: "none",
      color: theme.colors.text
    }}
    {...props}
  />
);

const LogoWrapper = styled.div`
  margin-left: 0;
  padding: 10px 20px;
  font-size: 1.5rem;
  margin-right: auto;
  font-family: Arial;
  position: relative;
`;

const Rect = styled.div`
  border: 2px solid black;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const InnerRect = styled(Rect)`
  top: -5px;
  left: -5px;
`;

const OuterRect = styled(Rect)`
  top: 2px;
  left: 2px;
`;

export const Logo = () => (
  <Link href={"/"} style={{ textDecoration: "none", color: "black" }}>
    <LogoWrapper>
      hemp-market.pl
      <InnerRect />
      <OuterRect />
    </LogoWrapper>
  </Link>
);
