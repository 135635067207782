import theme from "../../gatsby-plugin-theme-ui";
import { Button } from "rebass";
import React from "react";

export default props => (
  <Button
    bg={"white"}
    color={theme.colors.text}
    {...props}
    sx={{
      border: "2px solid #5F8575",
      borderRadius: "0",
      cursor: "pointer",
      outline: "none",
      ...props.sx
    }}
  />
);
