import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Header } from "./Header";
import { Text, Flex, Box, Link } from "rebass";

const Layout = ({ children, pageName }) => {
  let className = "";

  if (pageName) {
    className = `${className} page-${pageName}`;
  }

  return (
    <Box>
      <Helmet bodyAttributes={{ class: className }}>
        <title>hemp-market.pl</title>
      </Helmet>
      <div className="wrapper">
        {/*<Header />*/}

        <Box px={24}>{children}</Box>

        {/*<Footer />*/}
      </div>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string
};

export default Layout;
